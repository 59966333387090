.quarantine-tabs-container {
    margin-top: 10px;
}

.quarantine-tab {
    margin-top: -15px;
}

.blocked-domains-tab {
    background: #fff;
    overflow: auto;
    height: calc( 100vh - 250px );
    .ant-list-header {
        position: sticky;
        top: 0;
        z-index: 111;
        background: #fff;
    }
}

.blocked-domains-heading {
    padding: 10px 0;
    position: sticky;
    top: 0 !important;
}
