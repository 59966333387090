body {
    margin: 0;
    height: 100vh;
}

.display-flex {
    display: flex;
}

.display-inline-flex {
    display: flex;
    align-content: center;
}

.h-100 {
    height: 100%;
}

.p-16 {
    padding: 16px;
}

.m-16 {
    margin: 16px;
}

.ml-16 {
    margin-left: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.mt-auto {
    margin: auto 0;
}

.csv-download-button {
    cursor: pointer;
    background: none;
    border: none;
}