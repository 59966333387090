.header {

  .navbar-title {
    display: flex;

    .navbar-logo {
      background: #f6f6f6;
      border-radius: 4px;
      display: flex;
      height: 40px;
      margin: auto;
      & img {
        margin: auto;
      }
    }

    .navbar-heading {
      color: #f6f6f6;
      margin: auto;
      padding-left: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navbar-buttons {
    display: inline-block;
    margin-left: auto;
  }
}