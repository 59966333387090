.body-bg {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  margin: 0 auto;
  background-size: cover;
  font-size: 14px;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  label {
    font-weight: 400;
  }
  .msg {
    margin-top: 20px;
    border: 1px solid white;
    color: #271515;
    background-color: #ffab00;
    text-align: center;
    padding-top:5px;
    font-weight: bold;
  }
  .login-page {
    margin: 0 auto;
    padding: 16% 0 8%;
    color: #ffffff;
    text-align: center;
    margin-bottom: 15px;
    width: 64%;
    h4.welcome-text {
      font-size: 24px;
      font-weight: 400;
    }
    h6.login-text {
      font-size: 16px;
    }
    .logo-center {
      margin: 15px 0px;
    }
    .field-design {
      background: rgba(0, 0, 0, 0.5490196078431373);
      border: 1px solid #e1e6e7;
      border-radius: 20px;
      padding: 6px 20px;
      line-height: 24px;
      color: #d7d7d7;
      font-size: 13px;
    }
    .form-control:focus {
      border-color: #66afe9;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }
    .welcome-login {
      padding: 30px 0px;
    }
    .welcom-form label {
      color: #d7d7d7;
    }
    .login-loader {
      border: 3px solid #337ab7 !important;
      border-radius: 50%;
      border-top: 3px solid #f3f3f3 !important;
      width: 20px;
      height: 20px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
  }
  .p-10 {
    padding: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .gradient-border {
    color: #d7d7d7;
    min-height: 340px;
  }
  .gradient-border:after {
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 3px;
    height: 50%;
    background-image: linear-gradient(to bottom, #02b6c4 5%, transparent);
  }
  .gradient-border:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 0;
    width: 3px;
    height: 50%;
    background-image: linear-gradient(to top, #02b6c4 5%, transparent);
  }
  .login-page .gradient-border {
    min-height: auto;
  }
  button.login-btn {
    margin: 0 auto;
    min-width: 100px;
    min-height: 40px;
    background: #02b6c4;
    border: none;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px !important;
    }
  }
  @media (max-width: 1024px) {
    .login-page {
      padding: 16% 0 0%;
      width: 100%;
    }
    .cloud-logo {
      width: 40%;
    }
    .width-25px, .aws-form {
      width: 45%;
      margin: 0 auto;
    }
  }
  @media (max-width: 767px) {
    .gradient-border {
      min-height: auto;
      border-style: solid;
      border-image: linear-gradient(to bottom, #02B6C4 1%, #00FFEA 50%) 0 0 98 0 repeat;
    }
  }
  @media (max-width: 490px) {

    .login-page .gradient-border {
      min-height: auto;
    }

    .login-page {
      width: 100%;
    }
  }

}