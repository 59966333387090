.table-container {
    .searchbar {
        position: sticky;
        top: 10px;
        left: 10px;
        margin: 0 10px;
        width: 380px;
        z-index: 2;
    }
    .sql-table {
        margin-top: 20px;
    }
    .download {
        padding-top: 7px;
        font-size: 18px;
    }
}
