.server-health-layout {
  padding: 16px;
}
#no-print-server-health{
  column-gap: 10px;
}

@page {
  size: a4 portrait;
  margin: 30px !important;
}

@media print {
  body {
    visibility: hidden;
  }
  #server-health-layout {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    #no-print-server-health{
      visibility: hidden;
    }
  }
  .health-card {
    break-inside: avoid;
  }
}