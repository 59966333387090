.dot{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.red-dot{
    background-color: rgb(255, 36, 36);
}

.green-dot{
    background-color: rgb(44, 183, 187);
}

.head {
    background-color: #f1f1f1;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 15px;
}
.value{
    padding: 8px 16px;
}
.vertRow{
    /* height: 30px; */
    border-bottom: 1px solid rgb(220, 219, 236);
    /* margin:0 5px; */
}
