.grid-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .form-item-icon {
    color: gray;
    padding-right: 4px;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5cb4bd;
    border-color: #5cb4bd;
  }
  
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5cb4bd;
  }
  
  .link-cell {
    color: #5cb4bd;
    cursor: pointer;
  }
  
  .default-settings-table {
    text-align: left;
    tr th, tr td{
      min-width: 160px;
    }
  }
