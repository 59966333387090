.console-container {
    display: flex;
    flex-direction: column;
    position: relative;
    .console-div {
        height: 45vh;
        width: 100%;
    }
    .stdout {
        background: #fff;
        width: 100%;
        height: 100%;
        overflow-y: auto
    }
    .execute {
        z-index: 1000;
    }
}