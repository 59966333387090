.create-modal {
  position:absolute;
  top: 80px;
  left: 50%;
  margin-left: -20em;
  //border: 1px solid #ededed;
  width: 600px;
  //height: 600px;
  box-shadow: -1px 6px 20px 0px #cccccc;
  z-index: 100;
}

.close-button {
  background: transparent;
  border: none !important;
}