.custom-table {
  //padding: 50px 0;
  //overflow: hidden;

  //tr:nth-child(even) {
  //  background-color: #fafafa;
  //}

  .ant-spin-nested-loading {
    padding: 16px 0;
  }
  .ant-table-cell a {
    color: #5cb4bd !important;
  }
}

.row-selection {
  .ant-table-row {
    cursor: pointer;
    &:hover,&:focus {
      .ant-table-cell {
        background: #e7e7e7;
      }
    }
  }
}

.dot{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.red-dot{
  background-color: rgb(255, 36, 36);
}

.green-dot{
  background-color: rgb(44, 183, 187);
}

.yellow-dot{
  background-color: #ffc107;
}

.link-cell {
  color: #5cb4bd;
  cursor: pointer;

  &:hover, &:focus {
    color: #25a8b6;
  }
}